/* AdminSidebar.css */

.sidebar {
    background-color: #1f7fdf; /* Dark blue background */
    color: white; /* White text color */
    width: 250px; /* Sidebar width */
    padding: 1rem; /* Padding around the content */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items to the start of the sidebar */
    height: 100vh; /* Full viewport height */
    position: fixed; /* Fixed position to the side */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure the sidebar stacks on top */
  }
  
  .hamburger-menu {
    display: none; /* Initially hidden, shown in responsive design */
    cursor: pointer;
    padding: 1rem 0;
    align-self: start; /* Align to the start of the sidebar */
  }
  
  .admin-title {
    font-size: 1.5rem; /* Larger font for the title */
    margin-bottom: 1rem; /* Space below the title */
  }
  
  .admin-menu {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    margin: 0;
  }
  
  .admin-menu-item {
    display: block;
    text-align: left;
    text-decoration: none; /* Remove underline from links */
    color: white; /* White text color for menu items */
    padding: 1rem; /* Padding for clickable area */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .admin-menu-item:hover,
  .admin-menu-item:focus {
    background-color: #2d3f64; /* Slightly lighter blue on hover/focus */
  }
  
  .logout-button {
    background-color: #d9534f; /* Bootstrap's btn-danger color */
    color: white;
    padding: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-top: auto; /* Push the button to the bottom */
  }
  
  .logout-button:hover,
  .logout-button:focus {
    background-color: #c9302c; /* Darken the color on hover/focus */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .hamburger-menu {
      display: block; /* Show the hamburger menu on smaller screens */
    }
  
    .sidebar {
      transform: translateX(-100%); /* Hide off-screen to the left */
      transition: transform 0.3s ease; /* Animate the sidebar movement */
    }
  
    .sidebar.open {
      transform: translateX(0); /* When open, move into view */
    }
  }

  .notification-indicator {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    margin-left: 10px; /* Adjust as needed */
  }