/*
  Theme Name: APPIX - Mobile App Landing Page Responsive React Template
  Theme URL: https://mediacity.co.in/appix/
  Author: Media City
  Author URI: https://mediacity.co.in/
  Creation Date: 25 October 2023
  Description: A default stylesheet for URL APPIX - Mobile App Landing Page Responsive React Template.
  Version: 1.0
*/
/* --------Font--------------- */
/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap'); */

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-ExtraBold.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-Medium.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-SemiBold.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-Regular.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-Light.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-Bold.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope/Manrope-ExtraLight.woff2") format("woff2"),
    url("../fonts/manrope/Manrope-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* -----------Css-variable------ */

:root {
  --light-yellow: #fdf7ec;
  --primery: #1f7fdf;
  --dark-black: #181a22;
  --dark-greay: #9599ab;
  --greay: #afafaf;
  --text-greay: #848484;
  --black: #000000;
  --bg-white: #ffffff;
  --text-white: #fff;
  --red-color: #ff0000;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  overflow: clip;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
  padding-bottom: 10px;
}

/* ------Common-Css------------- */
/* 
html {
    scroll-behavior: smooth
} */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.7;
  font-family: "Manrope", sans-serif;
  color: var(--black);
  background-color: white;
  /* overflow-x: hidden; */
  max-width: 100%;
}

.page_wrapper {
  width: 100%;
  overflow-x: hidden;
}



html {
  /* overflow-x: hidden; */
  max-width: 100%;
} 


a {
  text-decoration: none;
  color: var(--black);
  transition: 0.4s all;
}

a:hover {
  text-decoration: none;
  color: var(--primery);
}

ul,
li {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

button:focus,
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

h3 {
  font-size: 20px;
  color: var(--black);
  font-weight: 700;
}


.white_text p,
.white_text .section_title h2,
.white_text span,
.white_text h3 {
  color: var(--text-white);
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.section_title {
  text-align: center;
}

/* section heading h2 */
.section_title h2 {
  font-size: 55px;
  font-weight: 800;
  color: var(--black);
  letter-spacing: -2px;
}

.section_title h2 span {
  color: var(--primery);
}

.section_title h2 span img {
  margin-left: 10px;
}

.section_title.white_text h2,
.section_title.white_text p {
  color: var(--bg-white);
}

.title_badge {
  color: var(--bg-white);
  display: inline-block;
  padding: 2px 20px;
  border-radius: 100px;
  background-color: var(--primery);
  margin-bottom: 10px;
}

.row_am {
  padding: 50px 0;
}

.row.abt_text{
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
  text-align: center;
}

/* purple button */
.btn {
  font-weight: 700;
}

.puprple_btn {
  color: var(--text-white);
  background-color: var(--primery);
  font-size: 16px;
  padding: 9px 30px;
  border-radius: 25px;
  margin-left: 20px;
  position: relative;
  transform: translate3d(-3px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.4s all;
  z-index: 2;
}

.puprple_btn:hover {
  color: var(--text-white);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.btn_block {
  position: relative;
  display: inline-block;
}

.btn_bottom {
  z-index: 1;
  border: 1px solid var(--primery);
  border-radius: 100px;
  position: absolute;
  top: -8px;
  left: 8px;
  right: -12px;
  transform: translate(-0.52em, 0.52em);
  width: 100%;
  height: 100%;
}

/* white button */
.white_btn .puprple_btn {
  background-color: var(--bg-white);
  color: var(--dark-black);
}

.white_btn .btn_bottom {
  border-color: var(--bg-white);
}

.white_btn:hover {
  color: var(--black);
}

/* slider controls */
.owl-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.owl-carousel .owl-dots button {
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid var(--primery);
  border-radius: 15px;
  margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
  background-color: var(--primery);
}

.container.container-sm {
  max-width: 780px;
}

/* -------------Preloader-Css-Start-------------- */

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999999;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primery);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--dark-black);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--dark-greay);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.spin_text {
  transform-origin: 50% 50% 0;
  transform: rotate(0deg);
  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ------Round Animation------- */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
  animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
  position: relative;
  animation: moving_position_animatin 6s infinite linear;
  z-index: -1;
}

@keyframes moving_object {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moving_position_animatin {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

/* -----------Header-Css-Start------------------- */
/* header wraper */
header {
  position: sticky; 
  top: 0;
  width: 100%;
  z-index: 99999;
  transition: 0.4s all;
  backdrop-filter: blur(5px);
  background-color: #1f7fdf;
  border-bottom: 1px solid #cfcbc4;
}

/* navigation bar */
.navbar {
  padding: 0;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 40px 12px;
  color: white;
  font-weight: 600;
  transition: 0.4s all;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: var(--bg-white);
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
}

.navbar-expand-lg .navbar-nav .nav-item .btn_block {
  margin-left: 30px;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  color: var(--text-white);
  background-color: var(--primery);
  font-size: 16px;
  padding: 9px 40px;
  border-radius: 25px;
  position: relative;
  transform: translate3d(-3px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.4s all;
  z-index: 2;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.navbar-brand img {
  width: 130px;
}

.navbar-brand h3 {
  color: var(--primery);
  font-weight: 800;
  font-size: 34px;
  margin: 0;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
      opacity: 0.5;
  }
  to {
      opacity: 1;
      /* visibility: visible; */
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0.5;
      /* visibility: hidden; */
  }
}


/* navigation bar dropdown */
.navbar-expand-lg .navbar-nav .has_dropdown {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px 10px 0 0;
  transition: 0.4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
  position: relative;
  right: 8px;
  top: 2px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
  position: absolute;
  top: 100%;
  background-color: var(--bg-white);
  border-radius: 0 0 10px 10px;
  min-width: 210px;
  max-width: 230px;
  margin-top: -10px;
  transition: 0.4s all;
  opacity: 0;
  pointer-events: none;
  border-bottom: 3px solid var(--primery);
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
  margin-left: 0;
  padding: 15px 20px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
  font-size: 15px;
  position: relative;
  transition: 0.4s all;
  line-height: 35px;
  font-weight: 600;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover {
  color: var(--primery);
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover > a,
.navbar-expand-lg .navbar-nav .has_dropdown:hover > .drp_btn {
  color: var(--primery);
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
  opacity: 1;
  pointer-events: all;
  margin-top: 1px;
}

/* navigation toggle menu */
.toggle-wrap {
  padding: 10px;
  position: relative;
  cursor: pointer;

  /*disable selection*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.toggle-bar {
  width: 25px;
  margin: 10px 0;
  position: relative;
  border-top: 4px solid var(--bg-white);
  display: block;
  color: white;
}

.toggle-bar::before,
.toggle-bar::after {
  content: "";
  display: block;
  background: white;
  height: 4px;
  width: 30px;
  position: absolute;
  top: -12px;
  right: 0px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -ms-transform-origin: 13%;
  -webkit-transform-origin: 13%;
  transform-origin: 13%;
}

.white_header .toggle-bar,
.white_header .toggle-bar::before,
.white_header .toggle-bar::after {
  border-top: 4px solid var(--bg-white);
}

.toggle-bar::after {
  top: 4px;
}

.toggle-wrap.active .toggle-bar {
  border-top: 6px solid transparent;
}

.toggle-wrap.active .toggle-bar::before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle-wrap.active .toggle-bar::after {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* ---------Hero-Slider-Css-Start------------------ */
/* hero slider wraper */
.banner_section {
  margin-top: 70px;
  position: relative;
  padding-bottom: 70px;
}

.banner_section::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 1250px;
  height: 1000px;
  /* background-image: url(../images/blue_dotes.png);
  opacity: 20%; */
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

.banner_section .container {
  position: relative;
}

.banner_section .row {
  align-items: center;
}

/* hero slider text */
.banner_section .banner_text {
  margin-top: -50px;
}

/* hero slider heading h1 */
.banner_section .banner_text h1 {
  font-size: 50px;
  color: var(--dark-black);
  letter-spacing: -1.5px;
  font-weight: 700;
  margin-bottom: 40px;
}

.banner_section .banner_text h1 span {
  color: var(--primery);
}

.banner_section .banner_text .type-wrap span {
  font-weight: 700;
  color: var(--primery);
}

/* hero slider button */
.banner_section .app_btn {
  display: flex;
  align-items: center;
}

/* hero slider list */
.banner_section .app_btn li a {
  display: block;
  padding: 15px 35px;
  background-color: var(--black);
  border: none;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

.banner_section .app_btn li a img {
  transition: 0.4s all;
  height: 36px;
}

/* hero slider users */
.banner_section .used_app {
  margin: 35px 0;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin: 0 10px 20px 0;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -20px;
}

.banner_section .used_app ul li:not(:first-child) img {
  border: 3px solid var(--bg-white);
  border-radius: 100px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
}

/* hero slider images */
.banner_section .banner_slider {
  display: flex;
  position: relative;
  z-index: 1;
}

.banner_section .banner_slider .left_icon {
  position: absolute;
  left: 0px;
  bottom: 150px;
  z-index: 9999;
}

.banner_section .banner_slider .right_icon {
  position: absolute;
  right: 130px;
  top: -10px;
}

/* hero slider mobile frame */
.banner_section .banner_slider .slider_frame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 300px;
}

.banner_section .banner_slider #frmae_slider {
  width: 280px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 40px;
}

.banner_section .banner_slider #frmae_slider::before {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  background-color: #fff;
  width: 100%;
  height: 90.5%;
  border-radius: 23px;
}

.banner_section .banner_slider::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%) rotate(-8deg);
  width: 480px;
  height: 480px;
  background-color: var(--primery);
  z-index: -5;
  border-radius: 30px;
}

/* hero slider control dots */
.banner_section .owl-dots {
  margin-top: 40px;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
}

/* hero static image */

.banner_section .banner_slider .hero_img {
  text-align: center;
  width: 100%;
}

/* -----Typing Text------- */
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ---------Spin Block---------- */
.spinBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  position: relative;
}

.spinBlock::before {
  content: "";
  position: absolute;
  width: calc(100% - 40px);
  height: 1px;
  left: 20px;
  top: 50.5%;
  background-color: var(--dark-black);
}

.spinBlock .spin_box {
  display: block;
  width: 155px;
  height: 155px;
  background-color: var(--dark-black);
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.spinBlock .spin_box .downsign {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 75px;
  height: 75px;
  background-color: var(--primery);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: var(--bg-white);
}

.spinBlock .spin-text {
  transform-origin: 50% 50% 0;
  transform: rotate(0deg);
  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

/* -----------------Task-App-Section-Css-Start------------------ */
.task_app_section {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.task_app_section .task_block {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 60px 150px;
  position: sticky;
  z-index: 99;
  border-radius: 30px;
  overflow: hidden;
}

.task_app_section .dotes_blue {
  position: absolute;
  left: -80px;
  bottom: -100px;
}

.task_app_section .task_block .row {
  align-items: center;
}

/* Task App section wraper */
.task_app_section .task_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Task App section images*/
.task_app_section .task_img img {
  max-width: 100%;
  border-radius: 20px;
}

.task_app_section .task_img::before {
  content: "";
  position: absolute;
  left: 42%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(-9deg);
  width: 380px;
  height: 380px;
  background-color: var(--primery);
  border-radius: 30px;
}

.task_app_section .task_img .screen_img {
  margin-left: -115px;
  margin-top: 50px;
}

.task_app_section .task_text .section_title {
  text-align: left;
}

.task_app_section .task_text .section_title h2 {
  margin-bottom: 15px;
  font-size: 35px;
}

.task_app_section .task_text .section_title .icon {
  display: block;
  width: 80px;
  height: 80px;
  background-color: var(--bg-white);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 35px 0;
}

.task_app_section .task_text .section_title .icon img {
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

/* ------------About us Section Css Start-------- */
.about_section {
  position: relative;
}

.about_section::before,
.about_section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  /* background-image: url(../images/yellow_dotes_tr.png); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 410px;
  background-position: center;
  z-index: -1;
}

.about_section .section_title {
  margin-bottom: 70px;
}

.about_section .app_statstic {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.about_section .app_statstic li:first-child,
.about_section .app_statstic li:last-child {
  width: 100%;
  text-align: center;
}

.about_section .app_statstic li .text {
  width: 200px;
  height: 80px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--bg-white);
  border: 1px solid var(--primery);
  margin: 0 auto;
}

.about_section .app_statstic li .text p span {
  font-weight: 700;
  font-size: 30px;
  color: var(--primery);
}

.about_section .app_statstic li .text p {
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  margin: 0;
  line-height: 1.2;
}

.about_section .video_block {
  position: relative;
  width: 275px;
  margin: 35px 0;
}

.about_section .video_block .thumbnil {
  max-width: 100%;
  border-radius: 20px;
}

.about_section .video_block .thumbnil img {
  border-radius: 20px;
}

.about_section .video_block .playBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 26, 34, 0.5);
  border-radius: 100px;
  width: 100px;
  height: 100px;
}

.about_section .video_block .playBtn .play_icon {
  position: absolute;
  margin: auto;
  z-index: 99;
}

/* how it works video model   */
.modal {
  z-index: 999999;
}

.modal-backdrop.show {
  z-index: 99999;
  opacity: 0.7;
}

.youtube-video .modal-dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  padding: 0 15px;
  height: 100%;
  max-width: 1240px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#video-container {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

iframe#youtubevideo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.youtube-video .modal-footer {
  border: none;
  text-align: center;
  display: block;
  padding: 0;
}

.youtube-video .modal-content {
  background: none !important;
  border: none;
}

#close-video {
  color: #323542;
  font-size: 30px;
}

/* ---------- text List Flow Css Start ------------- */

.text_list_section .slider_block {
  background-color: var(--primery);
  padding: 20px 0;
  transform: rotate(-1deg); 
  /* pointer-events: none; */
  width: 100%;
  overflow-x: hidden;
}

.text_list_section .down_fix {
  transform: rotate(-5deg);
  position: relative;
  bottom: -30px;
  z-index: 9;
}

.text_list_section .down_fix::after {
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  width: 22px;
  height: 22px;
  background-image: url(../images/bigstar.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.text_list_section.rotet_down {
  transform: rotate(0.5deg);
}

.text_list_section .owl-stage,
.text_list_section .owl-stage-outer,
.textFlow_list .owl-stage,
.textFlow_list .owl-stage-outer {
  height: 55px;
}

.text_block {
  display: flex;
  align-items: center;
}

.text_block span {
  color: var(--text-white);
  font-size: 50px;
  font-family: var(--font-bebas);
  font-weight: 700;
  line-height: 1;
}

.text_block .mark_star {
  margin: 0 60px;
  font-family: "Playball", cursive;
}

/* ------------Service App Section Css------------ */
.service_section {
  position: relative;
  /* margin-bottom: 80px; */
  /* margin-top: 80px; */
  background-color:#ebebeb62;
}

.service_section .service_blocks {
  padding: 50px 0;
}

.service_section .service_badge {
  background-color: var(--bg-white);
  color: var(--primery);
  padding: 5px 20px;
  border-radius: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.service_section .service_badge span {
  margin-left: 3px;
}

.service_section .service_blocks .img img {
  max-width: 100%;
  border-radius: 20px;
}

.service_section .service_text {
  padding-right: 150px;
}

.service_section .service_text.right_side {
  padding-left: 150px;
  padding-right: 0;
}

.service_section .service_text h2 {
  font-size: 35px;
  font-weight: 700;
  color: var(--black);
  margin: 25px 0;
}

.service_section .service_text h2 span {
  color: var(--primery);
}

.service_section .service_text .listing_block li {
  display: flex;
  gap: 15px;
}

.service_section .service_text .listing_block li .icon span {
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--text-white);
  background-color: var(--dark-black);
  border-radius: 100px;
  font-size: 8px;
  margin-top: 3px;
}

.service_section .service_text .listing_block li .text {
  width: calc(100% - 50px);
}

.service_section .service_text .feature_list li {
  display: flex;
  align-items: start;
  gap: 5px;
}

.service_section .service_text .feature_list li .icon span {
  color: var(--primery);
  font-size: 18px;
}

.service_section .service_text .feature_list li p {
  margin: 0;
  padding-top: 2px;
  margin-bottom: 10px;
}

.service_section .service_text .btn_block {
  margin-top: 40px;
}

.service_section .service_text .btn {
  text-transform: capitalize;
  font-weight: 700;
}

/* -------------How It Work Section Css Start------------- */
.how_it_section {
  position: relative;
  padding: 0 15px;
}

.how_it_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 100px 0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.how_it_inner .dotes_blue {
  position: absolute;
  top: -65%;
  left: 50%;
  transform: translateX(-50%);
}

.how_it_inner .section_title {
  margin-bottom: 50px;
  position: relative;
}

.how_it_inner .steps_block {
  text-align: center;
  position: relative;
}

.how_it_inner .steps_block .icon {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--light-yellow);
  margin: 0 auto;
}

.how_it_inner .steps_block .text {
  margin-top: 40px;
}

.how_it_inner .steps_block .text .social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px 0;
}

.how_it_inner .steps_block .text .social li a {
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid var(--bg-white);
  border-radius: 100px;
  text-align: center;
  line-height: 38px;
  color: var(--text-white);
  transition: 0.4s all;
}

.how_it_inner .steps_block .text .social li a:hover {
  background-color: var(--primery);
  border-color: var(--primery);
}

.how_it_inner .steps_block .text .tag_text {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px 0;
}

.how_it_inner .steps_block .step {
  width: 40px;
  height: 40px;
  border: 1px dashed var(--dark-greay);
  color: var(--dark-greay);
  text-align: center;
  line-height: 38px;
  display: block;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 30px;
  position: relative;
}

.how_it_inner .steps_block.step_border::after {
  content: "";
  position: absolute;
  left: calc(50% + 45px);
  bottom: 20px;
  width: calc(100% - 60px);
  height: 1px;
  border-bottom: 1px dashed var(--dark-greay);
}

.how_it_inner .text-center .btn_block {
  margin-top: 35px;
}

/* -------------Review Section Css Start-------------- */
.review_section {
  position: relative;
}

.review_section .positive_inner {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid var(--dark-greay);
}

.review_section .positive_inner .row {
  align-items: flex-start;
}

.review_section .positive_inner .row .sticky-top {
  top: 30px;
}

.review_section .google_rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.review_section .google_rating .star {
  display: flex;
  align-items: center;
}

.review_section .google_rating .star span,
.review_section .review_side .review_block .coustomer_info .star span {
  color: #1f7fdf;
}

.review_section .google_rating p {
  margin: 0;
  font-weight: 700;
}

.review_section .google_rating p img {
  height: 16px;
  nav-left: 5px;
}

.review_section .user_review {
  margin-bottom: 60px;
}

.review_section .user_review p {
  font-weight: 700;
}

.review_section .user_review p a {
  color: var(--primery);
}

.review_section .review_side .review_block {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 50px;
  margin-bottom: 30px;
}

.review_section .review_side .review_block .coustomer_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.review_section .coustomer_info .star {
  width: 100px;
  display: flex;
  justify-content: end;
}

.review_section .coustomer_info .avtar {
  display: flex;
  align-items: center;
  gap: 15px;
  width: calc(100% - 120px);
}

.review_section .coustomer_info .avtar img {
  width: 80px;
  aspect-ratio: 1/1;
  border-radius: 150px;
}

.review_section .coustomer_info .avtar .text {
  width: calc(100% - 100px);
}

.review_section .coustomer_info .avtar .text h3 {
  margin-bottom: 0;
}

.review_section .coustomer_info .avtar .text span {
  font-size: 15px;
}

.review_section .review_block p {
  margin: 0;
}

/* ------------inner page review Section Css Start---------- */

.review_section.page_ban {
  position: relative;
  padding-top: 80px;
}

.review_section.page_ban::before,
.review_section.page_ban::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-image: url(../images/yellow_dotes.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 40%;
  background-position: center;
  z-index: -1;
}

.review_section .review_side.innerpage_block {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.review_section .review_side.innerpage_block .review_block {
  width: 48%;
}

.review_section .positive_inner.in_page {
  border-bottom: none;
}

.review_section.page_ban .google_rating {
  display: block;
}

.review_section.page_ban .google_rating .star {
  display: block;
}

.review_section.page_ban .google_rating .rate_link {
  color: var(--primery);
  font-weight: normal;
}

/* ------------Our Client Listing Section Css Start---------- */
.our_client {
  position: relative;
}

.our_client::before,
.our_client::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  background-image: url(../images/yellow_dotes_tr.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 410px;
  background-position: center;
  z-index: -1;
}

.our_client .client_list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 50px;
}

.our_client .client_list .client_logo {
  background-color: var(--bg-white);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 86px;
}

.our_client .client_list .client_logo img {
  width: 150px;
  height: auto;
}

/* -------------------Pricing-Section---------------------- */
.pricing_section {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pricing_inner {
  max-width: 1370px;
  background-color: var(--dark-black);
  padding: 100px 0;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.pricing_inner .dotes_blue {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}

.pricing_inner .section_title {
  position: relative;
}

.pricing_inner .nav-tabs {
  border: none;
  justify-content: center;
  background-color: var(--text-white);
  border-radius: 10px;
  padding: 5px;
  width: 230px;
  margin: 50px auto;
  position: relative;
}

.pricing_inner .nav-tabs button {
  border: transparent;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 700;
  padding: 10px 30px;
  font-size: 14px;
}

.pricing_inner .nav-tabs button.active {
  background-color: var(--primery);
  color: var(--text-white);
}

.pricing_inner .pannel_block {
  background-color: var(--bg-white);
  border-radius: 20px;
  padding: 45px 40px;
}

.pricing_inner .pannel_block:not(.highlited_block) span,
.pricing_inner .pannel_block:not(.highlited_block) p,
.pricing_inner .pannel_block:not(.highlited_block) h2,
.pricing_inner .pannel_block:not(.highlited_block) h3 {
  color: var(--black);
}

.pricing_inner .pannel_block .heading {
  position: relative;
}

.pricing_inner .pannel_block .heading h3 {
  margin: 0;
}

.pricing_inner .pannel_block .heading span {
  font-size: 15px;
}

.pricing_inner .pannel_block .pricing {
  padding: 35px 0 25px 0;
  border-bottom: 1px solid var(--dark-greay);
}

.pricing_inner .pannel_block .pricing h3 {
  font-size: 48px;
  font-weight: 700;
  color: var(--primery);
  margin: 0;
}

.pricing_inner .pannel_block .pricing span {
  font-size: 15px;
  margin-left: -10px;
  color: var(--primery);
}

.pricing_inner .pannel_block .features {
  margin: 30px 0 35px 0;
}

.pricing_inner .pannel_block .features li {
  display: flex;
  align-items: start;
  margin: 5px 0;
}

.pricing_inner .pannel_block .features li p {
  margin: 0;
  width: calc(100% - 20px);
  font-weight: 500;
  padding-top: 2px;
}

.pricing_inner .pannel_block .features li .icon {
  margin-right: 5px;
  width: 17px;
}

.pricing_inner .pannel_block .features li .icon .icofont-check-circled {
  color: var(--primery);
}

.pricing_inner .pannel_block .features li .icon .icofont-close-circled {
  color: var(--red-color);
}

.pricing_inner .pannel_block.highlited_block {
  background-color: var(--primery);
}

.pricing_inner .pannel_block.highlited_block .pricing {
  border-color: #7995f7;
}

.pricing_inner .pannel_block.highlited_block .pricing h3,
.pricing_inner .pannel_block.highlited_block .pricing h3 span {
  color: var(--text-white);
}

.pricing_inner .pannel_block.highlited_block .offer {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--bg-white);
  color: var(--black);
  padding: 2px 10px;
  border-radius: 50px;
  line-height: 1.2;
  font-weight: 600;
  font-size: 13px;
}

.pricing_inner
  .pannel_block.highlited_block
  .features
  li
  .icon
  .icofont-check-circled {
  color: var(--text-white);
}

.pricing_inner .contact_text {
  margin-top: 40px;
  margin-bottom: 0;
}

.pricing_inner .contact_text a {
  color: var(--text-white);
  text-decoration: underline;
  line-height: 1.5;
}

/* -----------Interface-Section-Css-Start----------------- */

/* interface wraper */
.interface_section .screen_slider {
  margin-top: 35px;
  min-height: 720px;
}

/* interface images */
.interface_section .owl-item .screen_frame_img img {
  transform: scale(0.9);
  border: 2px solid #000;
  border-radius: 20px;
  transition: 1s all;
  margin: 0 auto;
}

.interface_section .owl-item.center .screen_frame_img img {
  transform: scale(1);
  border: 3px solid #000;
}

.interface_section .owl-dots {
  margin-top: 40px;
}

.interface_section .owl-dots .owl-dot {
  border: 1px solid var(--primery);
  background-color: var(--primery);
  background-color: transparent;
}

/* ---------Key Feature Section Css Start---------- */
.key_feature_section {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 80px;
}

.key_innner {
  background: var(--dark-black);
  max-width: 1370px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.key_innner .dotes_blue {
  position: absolute;
  top: -55%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.key_innner .section_title {
  margin-bottom: 35px;
  position: relative;
}

.key_innner .section_title h2 {
  color: var(--text-white);
}

.feature_box {
  text-align: center;
  background-color: var(--text-white);
  padding: 40px 30px 0 30px;
  border-radius: 20px;
}

.feature_box h3 {
  color: var(--primery);
}

.feature_box .img {
  margin-top: 40px;
}

.key_innner .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid var(--dark-greay) !important;
  transition: 0.4s all;
}

.key_innner .owl-nav button span {
  color: var(--dark-greay);
  font-size: 22px;
  line-height: 1.2;
}

.key_innner .owl-nav button.owl-prev {
  left: -50px;
}

.key_innner .owl-nav button.owl-next {
  right: -50px;
}

.key_innner .owl-nav button:hover {
  background-color: var(--bg-white);
  border-color: var(--bg-white);
}

.key_innner .owl-nav button:hover span {
  color: var(--primery);
}

/* --------------Downaload Section Css Start--------------- */
.downaload_section {
  position: relative;
}

.downaload_section .background_slider {
  padding: 200px 0;
}

.downaload_section .dowanload_slider .downlist {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.downaload_section .dowanload_slider .text_block span {
  position: relative;
  color: #ddd8cf;
}

.downaload_section .dowanload_slider {
  transform: rotate(-1.5deg);
  padding: 20px 0;
}

.downaload_section .yellow_dotes {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.downaload_section .yellow_dotes img {
  width: 850px;
}

.downaload_section .center_screen {
  text-align: center;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.downaload_section .app_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.downaload_section .app_btn li a {
  display: block;
  padding: 15px 35px;
  background-color: var(--black);
  border: none;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

.downaload_section .app_btn li:last-child {
  margin-left: 25px;
}

.downaload_section .app_btn li a img {
  transition: 0.4s all;
  height: 36px;
}

/* -------------blog-Section-Css-Start------------- */
.blog_section {
  position: relative;
}

.blog_section .section_title {
  margin-bottom: 50px;
}

.blog_section .blog_listing {
  display: flex;
  justify-content: space-between;
}

.blog_section .blog_listing .blog_post {
  background-color: var(--bg-white);
  padding: 40px;
  border-radius: 20px;
  width: calc(50% - 40px);
}

.blog_section .blog_listing .blog_post img {
  border-radius: 15px;
  max-width: 100%;
}

.blog_section .blog_listing .blog_post .text {
  margin-top: 30px;
}

.blog_section .blog_listing .blog_post .blog_info {
  display: flex;
  font-size: 14px;
}

.blog_section .blog_listing .blog_post .blog_info li:not(:last-child):after {
  content: "|";
  margin: 0 10px;
}

.blog_section .blog_listing .blog_post h3 {
  margin: 20px 0 25px 0;
  font-size: 26px;
}

.blog_section .blog_listing .blog_post h3 a {
  transition: 0.4s all;
}

.blog_section .blog_listing .blog_post .tag_more {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog_section .blog_listing .blog_post .tag_more .tag {
  font-size: 14px;
  font-weight: 500;
  color: var(--primery);
  border: 1px solid var(--primery);
  padding: 2px 20px;
  border-radius: 100px;
}

.blog_section .blog_listing .blog_post .tag_more a {
  font-weight: 700;
  color: var(--primery);
  transition: 0.4s all;
}

.blog_section .blog_listing .blog_post .tag_more a:hover {
  color: var(--dark-black);
}

/* ------Footer-Css-Start-------------- */
footer {
  background-color: var(--dark-black);
  padding: 100px 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 50px;
  background-image: url(../images/footer_bg.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}

footer .logo_side .logo-text {
  margin-bottom: 40px;
}

footer .logo_side .logo-text h2 {
  font-family: 'League Spartan', sans-serif;
  font-weight: 800;
  color: white; 
}

footer .logo_side .logo-text h3 {
  font-size: 36px;
}

footer .news_letter h3 {
  font-size: 26px;
}

footer .news_letter form .form-group {
  max-width: 430px;
  position: relative;
}

footer .news_letter form .form-group .form-control {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--bg-white);
  border-radius: 10px;
  min-height: 60px;
  color: var(--text-white);
  font-weight: 500;
}

footer .news_letter form .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--primery);
  color: var(--text-white);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  transition: 0.4s all;
}

footer .news_letter form .form-group button:hover {
  background-color: var(--bg-white);
  color: var(--primery);
}

footer .news_letter form .note {
  color: #7a7a7a;
}

footer .contact_info {
  display: flex;
  margin-top: 10px;
}

footer .contact_info li a {
  color: var(--text-white);
}

footer .contact_info li:not(:last-child)::after {
  content: "|";
  margin: 0 15px;
  color: var(--text-white);
}

footer .social_media {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

footer .social_media li a {
  width: 35px;
  height: 35px;
  border: 1px solid var(--dark-greay);
  border-radius: 100px;
  color: var(--text-white);
  display: block;
  text-align: center;
  line-height: 35px;
  transition: 0.4s all;
}

footer .social_media li a:hover {
  background-color: var(--bg-white);
  color: var(--dark-black);
}

footer .download_side {
  text-align: right;
  padding-top: 90px;
}

footer .download_side .app_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 40px;
}

footer .download_side .app_btn li a {
  display: block;
  padding: 15px 35px;
  background-color: var(--black);
  border: none;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

footer .download_side .app_btn li:last-child {
  margin-left: 25px;
}

footer .download_side .app_btn li a img {
  transition: 0.4s all;
  height: 36px;
}

.footer_bottom {
  max-width: 1370px;
  margin: 0 auto;
  background-color: #111218;
  border-radius: 20px;
  margin-top: 40px;
}

.footer_bottom .ft_inner {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.footer_bottom .ft_inner p {
  margin: 0;
  color: var(--greay);
  font-size: 14px;
}

.footer_bottom .ft_inner p a {
  color: var(--text-white);
  text-decoration: underline;
}

.footer_bottom .ft_inner .links {
  display: flex;
  justify-content: center;
}

.footer_bottom .ft_inner .links li a {
  color: var(--greay);
  transition: 0.4s all;
}

.footer_bottom .ft_inner .links li a:hover {
  color: var(--text-white);
}

.footer_bottom .ft_inner .links li:not(:last-child)::after {
  content: "|";
  margin: 0 10px;
  color: var(--greay);
}

/* footer go top button */
.go_top {
  position: fixed;
  right: 30px;
  bottom: 110px;
  cursor: pointer;
  transition: 0.4s all;
  display: none;
}

.go_top span {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primery);
  color: var(--text-white);
  border-radius: 150px;
  font-size: 25px;
}

.go_top:hover {
  bottom: 120px;
}

/* ===============About Page Css Start================== */
.about_us_section {
  padding-top: 80px;
  position: relative;
}

.about_us_section::before,
.about_us_section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-image: url(../images/blue_dotes.png);
  opacity: 20%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 80%;
  background-position: center;
  z-index: -1;
}

.about_us_section #about_slider .abt_slides img {
  height: 350px;
  object-fit: cover;
  width: 100%;
  border-radius: 15px;
}

.about_us_section .abt_text h2 {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -1px;
  max-width: 375px;
}

.about_us_section .abt_text h2 span {
  color: var(--primery);
}

/* ===============Why Choose Us Section Css Start================== */
.why_choose {
  position: relative;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.why_choose .why_choose_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 100px 0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.why_choose .why_choose_inner .dotes_blue {
  position: absolute;
  top: -65%;
  left: 50%;
  transform: translateX(-50%);
}

.why_choose .why_choose_inner .company_statistics ul {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  max-width: 940px;
  margin: 50px auto 0 auto;
}

.why_choose .why_choose_inner .company_statistics ul li {
  width: 25%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--primery);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 4;
}

.why_choose .why_choose_inner .company_statistics ul li:not(:last-child) {
  margin-left: -20px;
}

.why_choose .why_choose_inner .company_statistics ul li p span {
  font-size: 35px;
  font-weight: 700;
  color: var(--primery);
}

.why_choose .why_choose_inner .company_statistics ul li p {
  font-size: 18px;
  color: var(--black);
  font-weight: 700;
  margin: 0;
  line-height: 1.3;
}

/* ===============Our Value Section Css Start============== */
.our_value_section {
  position: relative;
}

.our_value_section::before,
.our_value_section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  /* background-image: url(../images/yellow_dotes_tr.png); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 410px;
  background-position: center;
  z-index: -1;
}

.our_value_section .value_slider {
  margin-top: 50px;
}

.our_value_section .value_slider .value_block {
  background-color: var(--bg-white);
  padding: 50px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 1px 20px rgba(156, 156, 156, 0.2);
}

.our_value_section .value_slider .value_block .icon {
  width: 90px;
  aspect-ratio: 1/1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primery);
  margin: 0 auto 30px auto;
}

.our_value_section .value_slider .value_block h3 {
  color: var(--primery);
}

.our_value_section .value_slider .value_block p {
  margin-bottom: 0;
}

.our_value_section .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid var(--greay) !important;
  transition: 0.4s all;
}

.our_value_section .owl-nav button span {
  color: var(--greay);
  font-size: 22px;
  line-height: 1.2;
}

.our_value_section .owl-nav button.owl-prev {
  left: -50px;
}

.our_value_section .owl-nav button.owl-next {
  right: -50px;
}

.our_value_section .owl-nav button:hover {
  background-color: var(--bg-white);
  border-color: var(--bg-white);
}

.our_value_section .owl-nav button:hover span {
  color: var(--primery);
}

/* ==============Why We Unique Section================ */
.unique_section {
  position: relative;
  padding: 50px 15px 0 15px;
}

.unique_section .section_title {
  margin-bottom: 40px;
}

.unique_section .unique_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 80px 0 50px 0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.unique_section .unique_inner .dotes_blue {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}

.unique_section .unique_inner .mb_5 {
  margin-bottom: 30px;
}

.unique_section .unique_inner .unique_box {
  background-color: var(--light-yellow);
  border-radius: 20px;
  padding: 25px 25px 170px 25px;
  height: 100%;
  position: relative;
}

.unique_section .unique_inner .unique_box h3 {
  color: var(--primery);
}

.unique_section .unique_inner .unique_box .text {
  min-height: 120px;
}

.unique_section .unique_inner .unique_box p {
  color: var(--black);
  margin: 0;
}

.unique_section .unique_inner .unique_box img {
  border: 1px solid #dadada;
  border-radius: 20px;
  width: 100%;
}

.unique_section .unique_inner .unique_box .img_block {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 0;
  padding: 20px;
  bottom: 0;
}

/* ==============About Service Section Css Start============== */
.about_service .video_player {
  position: relative;
}

.about_service .video_player .play_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.about_service .video_player .play_icon img {
  width: 120px;
  aspect-ratio: 1/1;
  opacity: 0.9;
}

/* ===============Testimonial Section Css Start============= */
.testimonial_section {
  position: relative;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.testimonial_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 100px 0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.testimonial_inner .dotes_blue {
  position: absolute;
  top: -75%;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial_section .title {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  width: 100%;
  text-align: center;
}

.testimonial_section .title .star {
  display: flex;
  justify-content: center;
  transform: rotate(-3deg);
  margin-bottom: -20px;
}

.testimonial_section .title .star span,
.testimonial_section .testimonial_box .testi_text .star span {
  color: #fc9400;
}

.testimonial_section .title .star .sub_title {
  padding: 2px;
}

.testimonial_section .title .sub_title {
  transform: rotate(-3deg);
  position: relative;
  bottom: -20px;
  z-index: 9;
}

.testimonial_section .title .sub_title::after {
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  width: 22px;
  height: 22px;
  background-image: url(../images/bigstar.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.testimonial_section .title .sub_title {
  color: var(--bg-white);
  display: inline-block;
  padding: 2px 20px;
  border-radius: 100px;
  background-color: var(--primery);
}

.testimonial_box {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 950px;
  margin: 0 auto;
  gap: 60px;
}

.testimonial_box .testi_img {
  width: 255px;
  position: relative;
}

.testimonial_box .testi_img .play_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.testimonial_box .testi_img .play_icon img {
  width: 80px;
  aspect-ratio: 1/1;
  opacity: 0.9;
}

.testimonial_box .testi_img .user_img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
}

.testimonial_box .testi_text {
  width: calc(100% - 315px);
}

.testimonial_box .testi_text .star {
  display: flex;
}

.testimonial_box .testi_text p {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
}

.testimonial_box .user_info {
  display: flex;
  gap: 5px;
}

.testimonial_box .user_info h3 {
  margin: 0;
  color: var(--text-white);
}

.testimonial_box .user_info span {
  font-size: 15px;
}

.testimonial_inner .client_logo_slider {
  margin-top: 90px;
  position: relative;
}

.testimonial_inner .client_logo_slider .img {
  background-color: var(--bg-white);
  width: 200px;
  height: 100px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.testimonial_inner .client_logo_slider .img img {
  width: 150px;
}

/* -----------Meet Our Team Section Css---------- */
.experts_team_section {
  margin-top: 50px;
  position: relative;
}

.experts_team_section::before,
.experts_team_section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background-image: url(../images/yellow_dotes_tr.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 410px;
  background-position: center;
  z-index: -1;
}

.experts_team_section .section_title {
  margin-bottom: 40px;
}

.experts_team_section .col-md-4 {
  padding-left: 25px;
  padding-right: 25px;
}

.experts_team_section .experts_box {
  position: relative;
  text-align: center;
  padding: 10px 10px 30px 10px;
  border-radius: 20px;
  background-color: transparent;
  transition: 0.4s all;
}

.experts_team_section .experts_box img {
  margin-bottom: 30px;
  max-width: 100%;
  border-radius: 20px;
}

.experts_team_section .experts_box .text h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--primery);
  transition: 0.4s all;
  margin: 0;
}

.experts_team_section .experts_box .text span {
  color: var(--black);
}

.experts_team_section .experts_box .social_media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  opacity: 0;
}

.experts_team_section .experts_box .social_media a {
  display: block;
  width: 33px;
  height: 33px;
  border: 1px solid #cccce2;
  border-radius: 50px;
  text-align: center;
  line-height: 31px;
  color: var(--text-white);
  margin: 0 5px;
  transition: 0.4s all;
  font-size: 15px;
}

.experts_team_section .experts_box .social_media a:hover {
  border-color: var(--bg-white);
  background-color: var(--bg-white);
  color: var(--dark-black);
}

.experts_team_section .experts_box:hover {
  background-color: var(--dark-black);
  box-shadow: 0 4px 10px #ede9fe;
}

.experts_team_section .experts_box:hover h3 {
  color: var(--text-white);
}

.experts_team_section .experts_box:hover span {
  color: var(--text-white);
}

.experts_team_section .experts_box:hover .social_media {
  opacity: 1;
}

/* ===============FAQ Section Css Start============ */
.faq_section {
  margin-top: 30px;
  margin-bottom: 60px;
}

.faq_section .section_title {
  margin-bottom: 50px;
}

.faq_section .nav-tabs {
  justify-content: center;
  margin-bottom: 30px;
  border: none;
}

.faq_section .nav-tabs .nav-item.show .nav-link,
.faq_section .nav-tabs .nav-link.active,
.faq_section .nav-tabs .nav-link:hover,
.faq_section .nav-tabs .nav-link {
  border: none;
  margin: 0;
}

.faq_section .nav-tabs .nav-item {
  position: relative;
}

.faq_section .nav-tabs .nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
  z-index: -1;
}

.faq_section .nav-tabs .nav-item button {
  background-color: #fff;
  padding: 10px 36px;
  border-radius: 10px;
  font-weight: 500;
}

.faq_section .nav-tabs .nav-item:first-child:before {
  border-radius: 10px 0 0 10px;
}

.faq_section .nav-tabs .nav-item:last-child:before {
  border-radius: 0 10px 10px 0;
}

.faq_section .nav-tabs .nav-item.show .nav-link,
.faq_section .nav-tabs .nav-link.active {
  background-color: #5379e4;
  color: #fff;
}

.faq_section .accordion {
  margin-bottom: -20px;
}

.faq_section .card {
  border: none;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
}

.faq_section .card .card-header {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
}

.faq_section .card .card-header button {
  width: 100%;
  text-align: left;
  color: #5379e4;
  text-decoration: none;
  padding: 0;
  font-weight: 700;
  position: relative;
  padding-right: 72px;
  font-size: 20px;
}

.faq_section .card .card-header button.collapsed {
  color: #000;
}

.faq_section .card .card-header button:focus {
  outline: none;
  box-shadow: none;
}

.faq_section .card .card-header button .icons i {
  position: absolute;
  right: 0;
  top: 4px;
  color: var(--dark-black);
}

.faq_section .card .card-header button.collapsed .icons .icofont-minus,
.faq_section .card .card-header button .icons .icofont-plus {
  display: none;
}

.faq_section .card .card-header button .icons .icofont-minus,
.faq_section .card .card-header button.collapsed .icons .icofont-plus {
  display: block;
}

.faq_section .card .card-body {
  padding-top: 0;
}

.faq-title{
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
  color: #1f7fdf;
}

.faq-row{
  display: flex;
  align-items:start;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.support-row{
  display: flex;
  flex-direction: column;
  align-items:center;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


/* =======================Our App Download Section Css Start======================= */
.download_app {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.download_app .task_block {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 0 150px;
  position: sticky;
  z-index: 99;
  border-radius: 30px;
  overflow: hidden;
}

.download_app .frame_img {
  text-align: right;
  margin-top: 70px;
}

.download_app .frame_img img {
  max-width: 100%;
}

.download_app .frame_img::before {
  content: "";
  position: absolute;
  right: -4%;
  bottom: -5%;
  transform: rotate(-8deg);
  width: 85%;
  aspect-ratio: 1/1;
  background-color: var(--primery);
  z-index: -5;
  border-radius: 30px;
}

.download_app .dotes_blue {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -60%;
}

.download_app .task_block .row {
  align-items: center;
}

.download_app .task_block .section_title {
  text-align: left;
}

.download_app .app_btn {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.download_app .app_btn li a {
  display: block;
  padding: 13px 30px;
  background-color: var(--bg-white);
  border: none;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

.download_app .app_btn li:last-child {
  margin-left: 15px;
}

.download_app .app_btn li a img {
  transition: 0.4s all;
  height: 36px;
}

/* =========Priicing Page Css Start============ */
.pricing_page_block {
  position: relative;
  padding-top: 80px;
}

.pricing_page_block::before,
.pricing_page_block::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background-image: url(../images/yellow_dotes.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 80%;
  background-position: center;
  z-index: -1;
}

.pricing_page_block .table_content {
  background-color: var(--bg-white);
  border-radius: 20px;
  margin-top: 50px;
}

.table_content .pricing_table {
  display: flex;
}

.table_content .pricing_table .pricing_block {
  width: 25%;
}

.table_content .pricing_table .pricing_block .pricing_title {
  padding: 50px 0 50px 30px;
  min-height: 295px;
}

.table_content .pricing_table .pricing_block .pricing_title h3 {
  margin: 0;
}

.table_content .pricing_table .pricing_block .pricing_title .pricing {
  padding: 25px 0;
}

.table_content .pricing_table .pricing_block .pricing_title .pricing h3 {
  font-size: 40px;
  font-weight: 700;
  color: var(--primery);
  margin: 0;
}

.table_content .pricing_table .pricing_block .pricing_title .pricing span {
  font-size: 15px;
  margin-left: -10px;
  color: var(--primery);
  font-weight: 400;
}

.table_content .pricing_table .mobile_text {
  display: none;
}

.table_content .pricing_table p {
  margin: 0;
}

.table_content .pricing_table ul li {
  padding: 15px 5px 15px 30px;
  border-top: 1px solid #e2dcd1;
}

.table_content .pricing_table .pricing_block ul li:last-child {
  border-bottom: 1px solid #e2dcd1;
}

.table_content .pricing_table .pricing_block .features li p {
  font-weight: 700;
}

.table_content .pricing_table .pricing_block ul li p .icofont-close-circled {
  color: var(--red-color);
}

.table_content .pricing_table .pricing_block ul li p .icofont-check-circled {
  color: var(--primery);
}

.table_content .bottom_text {
  padding: 25px 15px;
}

.table_content .bottom_text p {
  margin: 0;
}

.table_content .bottom_text p a {
  color: var(--primery);
  text-decoration: underline;
  font-weight: 700;
  transition: 0.4s all;
}

.table_content .bottom_text p a:hover {
  color: var(--dark-black);
}

/* =============Our Resource Section=============== */
.our_resource {
  position: relative;
  padding: 80px 0 40px 0;
}

.our_resource .section_title {
  text-align: left;
}

.our_resource .mail_block {
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 70px 60px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.our_resource .mail_block .dotes_blue {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.our_resource .mail_block .dotes_blue img {
  width: 100%;
}

.our_resource .mail_block .text {
  position: relative;
  z-index: 2;
  text-align: center;
}

.our_resource .mail_block .icon {
  display: block;
  margin-bottom: 20px;
}

.our_resource .mail_block h3 {
  font-size: 26px;
}

.our_resource .mail_block form .form-group {
  max-width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.our_resource .mail_block form .form-group .form-control {
  width: 100%;
  background-color: var(--bg-white);
  border: 1px solid var(--bg-white);
  border-radius: 10px;
  min-height: 60px;
  color: var(--dark-black);
  font-weight: 500;
}

.our_resource .mail_block form .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--primery);
  color: var(--text-white);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  transition: 0.4s all;
}

.our_resource .mail_block form .form-group button:hover {
  background-color: var(--bg-white);
  color: var(--primery);
  border-color: var(--primery);
}

.our_resource .mail_block form .note {
  color: #7a7a7a;
  text-align: left;
  margin: 0;
}

/* ===========Articles Section Start===================== */
.articles_section {
  position: relative;
}

.articles_section .section_title {
  text-align: left;
}

.articles_section .section_title h2 {
  font-size: 35px;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #cfcbc4;
}

.articles_section .filter_tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0 50px 0;
}

.articles_section .filter_tags li a {
  display: block;
  padding: 10px 22px;
  background-color: var(--bg-white);
  border-radius: 10px;
  color: var(--text-greay);
  transition: 0.4s all;
}

.articles_section .filter_tags li a:hover,
.articles_section .filter_tags li a.active {
  background-color: var(--primery);
  color: var(--text-white);
}

.blog_listings .listing_block {
  display: flex;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--bg-white);
}

.blog_listings .listing_block:not(:last-child) {
  margin-bottom: 40px;
}

.blog_listings .listing_block .img {
  width: 350px;
}

.blog_listings .listing_block .img img {
  width: 100%;
  border-radius: 20px;
}

.blog_listings .listing_block .blog_text {
  width: calc(100% - 600px);
  padding: 0 15px 0 60px;
}

.blog_listings .listing_block .blog_text .tag {
  font-size: 14px;
  font-weight: 500;
  color: var(--primery);
  border: 1px solid var(--primery);
  padding: 2px 20px;
  border-radius: 100px;
}

.blog_listings .listing_block .blog_text h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 25px 0;
}

.blog_listings .listing_block .blog_text h2 a {
  color: var(--dark-black);
}

.blog_listings .listing_block .blog_text h2 a:hover {
  color: var(--primery);
}

.blog_listings .listing_block .blog_text a {
  font-weight: 700;
  color: var(--primery);
  transition: 0.4s all;
}

.blog_listings .listing_block .blog_text a:hover {
  color: var(--dark-black);
}

.blog_listings .listing_block .authore_info {
  width: 250px;
}

.blog_listings .listing_block .authore_info {
  width: 250px;
}

.blog_listings .listing_block .authore_info .blog_info {
  display: flex;
  flex-wrap: wrap;
}

.blog_listings .listing_block .authore_info .blog_info li {
  position: relative;
  padding: 0 10px 0 15px;
  font-size: 15px;
}

.blog_listings .listing_block .authore_info .blog_info li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--black);
  border-radius: 10px;
  left: 0;
  position: absolute;
  top: calc(50% - 2.5px);
}

.blog_listings .listing_block .authore_info .avtar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.blog_listings .listing_block .authore_info .avtar img {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 100px;
}

.blog_listings .listing_block .authore_info .avtar .text {
  width: calc(100% - 60px);
}

.blog_listings .listing_block .authore_info .avtar .text h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.blog_listings .listing_block .authore_info .avtar .text span {
  font-size: 14px;
}

.pagination_block {
  padding: 0 15px;
  margin: 50px 0 0 0;
}

.pagination_block ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.pagination_block ul li a {
  display: block;
  padding: 10px 15px;
  background-color: var(--bg-white);
  color: var(--dark-black);
  border-radius: 5px;
  transition: 0.4s all;
  line-height: 1;
}

.pagination_block ul li .prev,
.pagination_block ul li .next {
  padding: 10px 20px;
}

.pagination_block ul li a:hover,
.pagination_block ul li a.active {
  background-color: var(--primery);
  color: var(--text-white);
}

/* =============Blog Single Css Start==================== */

.blog_detail_section {
  position: relative;
  padding: 80px 0 50px 0;
}

.blog_head {
  margin-bottom: 45px;
}

.blog_head .tags_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog_head .tag {
  font-size: 14px;
  font-weight: 500;
  color: var(--primery);
  border: 1px solid var(--primery);
  padding: 2px 20px;
  border-radius: 100px;
  background-color: var(--bg-white);
}

.blog_head .tags_info ul {
  display: flex;
  align-items: center;
}

.blog_head .tags_info ul li {
  position: relative;
  padding: 0 10px 0 15px;
  font-size: 15px;
}

.blog_head .tags_info ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--black);
  border-radius: 10px;
  left: 0;
  position: absolute;
  top: calc(50% - 2.5px);
}

.blog_head h1 {
  font-size: 50px;
  font-weight: 700;
  margin: 15px 0 30px 0;
}

.blog_head .avtar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.blog_head .avtar img {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 100px;
}

.blog_head .avtar .text {
  width: calc(100% - 60px);
}

.blog_head .avtar .text h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.blog_head .avtar .text span {
  font-size: 14px;
}

.blog_body .img {
  margin-bottom: 40px;
}

.blog_body .img img {
  width: 100%;
  border-radius: 20px;
}

.blog_body .listings {
  padding-left: 30px;
  margin: 30px 0;
}

.blog_body .listings li {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.blog_body .listings li .icon {
  color: var(--primery);
}

.blog_body .listings li p {
  margin: 0;
}

.blog_body h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.blog_body .yt_video {
  margin: 30px 0;
}

.blog_body .yt_video iframe {
  width: 100%;
  aspect-ratio: 1/0.5;
  border-radius: 20px;
}

.blog_body .highlight_text {
  padding: 10px 0 10px 30px;
  border-left: 5px solid var(--primery);
  margin: 30px 0;
}

.blog_body .highlight_text h3 {
  font-weight: 600;
  line-height: 1.5;
}

.blog_body .social_media {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}

.blog_body .social_media li a {
  width: 35px;
  height: 35px;
  border: 1px solid var(--dark-black);
  background-color: var(--dark-black);
  border-radius: 100px;
  color: var(--text-white);
  display: block;
  text-align: center;
  line-height: 35px;
  transition: 0.4s all;
}

.blog_body .social_media li a:hover {
  background-color: var(--bg-white);
  color: var(--dark-black);
}

/* comment section Css Start */
.comment_section .section_title h2 {
  font-size: 35px;
  text-align: left;
  border-top: 2px solid var(--greay);
  padding-top: 30px;
}

.comment_section ul {
  margin-top: 30px;
}

.comment_section ul li {
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid var(--greay);
}

.comment_section ul li:last-child {
  border-bottom: 1px solid var(--greay);
}

.comment_section ul li.replay_comment {
  margin-left: 110px;
}

.comment_section ul li .authore_info {
  display: flex;
  align-items: center;
  width: 260px;
}

.comment_section ul li .authore_info .avtar {
  width: 88px;
  margin-right: 20px;
}

.comment_section ul li .authore_info .avtar img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
}

.comment_section ul li .authore_info .text {
  width: calc(100% - 108px);
}

.comment_section ul li .authore_info .text h4 {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-purple);
}

.comment_section ul li .authore_info .text span {
  font-size: 14px;
}

.comment_section ul li .comment {
  width: calc(100% - 310px);
  margin-left: 50px;
}

.comment_section ul li .comment p {
  margin-bottom: 0;
}

.comment_form_section .section_title {
  text-align: left;
}

.comment_form_section .section_title h2 {
  font-size: 35px;
}

.comment_form_section form .form-group {
  margin-bottom: 30px;
}

.comment_form_section form .form-group .form-control {
  border-radius: 10px;
  border: 1px solid var(--greay);
  color: var(--dark-black);
  height: 60px;
  padding: 10px 20px;
}

.comment_form_section form .form-group .form-control::placeholder {
  color: var(--dark-black);
}

.comment_form_section form .form-group textarea.form-control {
  min-height: 135px;
}

.comment_form_section form .puprple_btn {
  padding: 9px 50px;
}

/* ==============Contact Us page Css Start==================== */
.contact_section {
  position: relative;
  padding: 80px 0 50px 0;
}

.contact_section::before,
.contact_section::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  background-image: url(../images/yellow_dotes_tr.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 410px;
  background-position: center;
  z-index: -1;
}

.contact_section .section_title {
  margin-bottom: 50px;
}

.contact_listing {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  text-align: center;
}

.contact_listing .icon {
  width: 90px;
  height: 90px;
  border-radius: 20px;
  background-color: var(--primery);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px auto;
}

.contact_listing li .lable {
  display: block;
  line-height: 1;
}

.contact_listing li a {
  font-weight: 700;
  font-size: 20px;
  transition: 0.4s all;
}

.contact_form {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.contact_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: var(--dark-black);
  padding: 75px 0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.contact_form .dotes_blue {
  position: absolute;
  top: -65%;
  left: 50%;
  transform: translateX(-50%);
}

.contact_form .section_title {
  margin-bottom: 50px;
}

.contact_form form .form-group {
  margin-bottom: 30px;
}

.contact_form form .form-group .form-control {
  border-radius: 10px;
  border: 1px solid #636778;
  color: var(--text-white);
  height: 60px;
  padding: 10px 20px;
  background-color: #323542;
}

.contact_form form .form-group .form-control::placeholder {
  color: var(--text-white);
}

.contact_form form .form-group textarea.form-control {
  min-height: 135px;
}

.contact_form form .form-group select {
  appearance: none;
  background-image: url("../images/rounded-down.svg");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 10px;
}

.contact_form form .puprple_btn {
  padding: 9px 50px;
}

.contact_form form .form-group .form-control:focus {
  box-shadow: none;
  border-color: var(--dark-greay);
}

.coustome_checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 5px;
  text-align: left;
}

.coustome_checkbox input {
  width: 15px;
  height: 15px;
}

.coustome_checkbox label {
  color: #afafaf;
  margin: 0;
  font-size: 16px;
}

.coustome_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 11px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #636778;
}

.coustome_checkbox input:checked ~ .checkmark {
  background-color: #636778;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.coustome_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.coustome_checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.map_block .container {
  max-width: 1400px;
}

.map_block .container iframe {
  width: 100%;
  height: 500px;
  border-radius: 20px;
}

/* ===========SignUp Section Css Start=============== */
.signup_section {
  padding: 75px 0;
}

.signup_section .top_part {
  padding-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signup_section .top_part .back_btn {
  position: absolute;
  left: 0;
}

.signup_section .form_block {
  display: flex;
  align-items: center;
  background-color: var(--bg-white);
  border-radius: 20px;
}

.signup_section .form_block .form_side {
  width: 50%;
  text-align: left;
  padding: 15px 100px;
}

.signup_section .form_block .form_side .section_title {
  text-align: left;
  margin-bottom: 50px;
}

.signup_section .form_block .form_side .section_title h2 {
  font-size: 35px;
}

.signup_section .form_block .form_side .section_title .title_badge {
  font-size: 14px;
}

.signup_section .form_block .form_side .section_title p {
  font-weight: 500;
}

.signup_section .form_block .form_side form .form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #999ca6;
  padding-left: 0;
}

.signup_section .form_block .form_side form .form-group {
  margin-bottom: 30px;
}

.signup_section .form_block .form_side form .form-control::placeholder {
  color: var(--dark-black);
  font-weight: 500;
}

.signup_section .form_block .form_side form .form-control:focus {
  box-shadow: none;
  color: var(--primery);
  border-color: var(--primery);
  font-weight: 500;
}

.signup_section .form_block .form_side form .forgate_check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 30px;
}

.signup_section .form_block .form_side form .forgate_check a {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

.signup_section .form_block .form_side form .forgate_check .coustome_checkbox {
  padding-top: 0;
}

.signup_section
  .form_block
  .form_side
  form
  .forgate_check
  .coustome_checkbox
  label {
  font-size: 14px;
  color: var(--dark-black);
  font-weight: 500;
}

.signup_section .form_block .form_side form .forgate_check .checkmark {
  top: 5px;
  border-radius: 4px;
}

.signup_section .form_block .coustome_checkbox input:checked ~ .checkmark {
  background-color: var(--primery);
  border-color: var(--primery);
}

.signup_section .form_block .form_side .puprple_btn,
.signup_section .form_block .form_side .btn_block {
  width: 100%;
}

.signup_section .form_block .form_side .google_btn {
  width: 100%;
  text-align: center;
  border-radius: 50px;
  color: var(--black);
  border: 1px solid var(--dark-black);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 20px 0 30px 0;
}

.signup_section .form_block .form_side .google_btn img {
  height: 20px;
  margin-right: 10px;
}

.signup_section .form_block .form_side .google_btn:hover {
  border-color: var(--primery);
}

.signup_section .form_side .sign_in_here p {
  margin: 0;
  color: #999ca6;
  font-weight: 500;
  font-size: 14px;
}

.signup_section .form_side .sign_in_here p a {
  color: var(--primery);
  text-decoration: underline;
}

.signup_section .form_side .sign_in_here p a:hover {
  color: var(--dark-black);
}

.signup_section .form_block .side_screen {
  width: 50%;
  background-color: var(--dark-black);
  padding: 75px 0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.signup_section .form_block .side_screen .dotes_blue {
  position: absolute;
  top: -45%;
  left: 50%;
  transform: translateX(-50%);
}

.signup_section .form_block .side_screen .left_icon {
  position: absolute;
  left: 70px;
  bottom: 150px;
  z-index: 9999;
}

.signup_section .form_block .side_screen .right_icon {
  position: absolute;
  right: 50px;
  top: 120px;
}

.signup_section .form_block .side_screen .left_icon img,
.signup_section .form_block .side_screen .right_icon img {
  filter: invert(1);
}

.signup_section .form_block .side_screen .scrren {
  text-align: center;
  position: relative;
  z-index: 9;
}

.signup_section .form_block .side_screen .scrren img {
  max-width: 100%;
}

.signup_section .form_block .side_screen .scrren::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%) rotate(-8deg);
  width: 380px;
  height: 380px;
  background-color: var(--primery);
  z-index: -5;
  border-radius: 30px;
}
.swiper-wrapper {
  display: flex;
}
.interface_section .swiper-slide .screen_frame_img img {
  transform: scale(0.9);
  border: 2px solid #000;
  border-radius: 20px;
  transition: 1s all;
  margin: 0 auto;
}
.interface_section .swiper-slide-active .screen_frame_img img {
  transform: scale(1);
  border: 3px solid #000;
}
.screen_frame_img img {
  width: 270px;
}

html {
  scroll-behavior: smooth;
}


@media screen and (max-width:576px) {

  .banner_section .banner_text h1 {
    font-size: 35px;
    color: var(--dark-black);
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 40px;
}

.service_section .service_text {
  padding-right: 10px;
  padding-left: 10px;
}

.service_section .service_text.right_side {
  padding-left: 10px;
  padding-right: 10px;
}

.text_list_section .slider_block {
display: none;
}

.key_innner .title_badge {
  display: none;
}

.coustomer_info{
display: flex;
flex-direction: column;
}

.avtar{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review_section .coustomer_info .avtar .text {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review_section .coustomer_info .star {
  width: 100px;
  display: flex;
  justify-content: center;
}

.review_section .coustomer_info .avtar .text h3 {
  margin-bottom: 0;
  text-align: center;
}

.review_section .coustomer_info .avtar .text span {
  font-size: 15px;
  text-align: center;
}

.service_text p {
margin-bottom: 25px;
}

.swiper-wrapper {
  display: flex;
  flex-direction: column;
  transform: none;
}

.swiper-slide{
  padding-bottom: 20px;
}


.key_innner .section_title {
  margin-bottom: 35px;
  position: relative;
}

.row.abt_text{
  text-align: center;
  gap: 5px;
}

.testimonial_box .testi_text {
  width: 90%;
  padding-bottom: 50px;
}

.testimonial_box .testi_img {
  width: 200px;
  position: relative;
}

.testimonial_box .user_info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.testimonial_box .testi_text p {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  padding-bottom: 10px;
}

.why_choose .why_choose_inner .company_statistics ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 940px;
  margin: 50px auto 0 auto;
}

.why_choose .why_choose_inner .company_statistics ul li p span {
  font-size: 20px;
  font-weight: 700;
  color: var(--primery);
}

.why_choose .why_choose_inner .company_statistics ul li p {
  font-size: 16px;
  color: var(--black);
  font-weight: 700;
  margin: 0;
  line-height: 1.3;
}

.section_title h2 {
  font-size: 35px;
  font-weight: 800;
  color: var(--black);
  letter-spacing: -2px;
  text-align: center;
}

.service_section .service_text .feature_list li p {
text-align: left;
}

.about_section .app_statstic li .text {
  width: 150px;
  height: 60px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--bg-white);
  border: 1px solid var(--primery);
  margin: 0 auto;
}

.about_section .app_statstic li .text p span {
  font-weight: 700;
  font-size: 20px;
  color: var(--primery);
}

.key_innner {
  padding-top: 50px;
  padding-bottom: 5px;
}

.feature_box{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature_box .img {
  margin-top: 10px;
}
.feature_box .img img{
 width: 200px;
 height: 254px;
}

.navbar-expand-lg .navbar-nav {
  align-items: flex-start;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 20px 12px;
  color: white;
  font-weight: 600;
  transition: 0.4s all;
}

}
