.customer-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    background-color: #f5f5f5; /* Light gray background */
}

.customer-info-card {
    width: 70%; /* Adjust width as needed */
    max-width: 700px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    overflow: hidden;
    position: relative; /* Needed for absolute positioning inside */
}

.customer-info-left-panel {
    background-color: #eee; /* Slightly darker gray for contrast */
    padding: 20px;
    width: 30%; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    position: relative; /* To position powered by at the bottom */
}

.customer-info-title {
    flex-grow: 1; /* Allows the title to expand and push the footer down */
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    width: 100%; /* Ensures it takes full available width */
    text-align: center;
}

.customer-info-powered-by {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: #666;
}

.customer-info-right-panel {
    padding: 20px;
    width: 70%; /* Adjust width as needed */
}

.customer-info-input[type="text"],
.customer-info-input[type="email"] {
    width: 100%; /* Full width of the form section */
    padding: 8px;
    margin: 6px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.customer-info-button {
    background-color: #1f7fdf; /* Green background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
}