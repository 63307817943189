.chats-page{
        padding: 20px;
        margin: auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* height: 100%; */
}

.active-chats-section, .reported-chats-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 10px;
}

/* .chat-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
} */

.chat-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    max-height: 150px;
    min-height: 150px;
    max-width: 200px;
}

.chat-card:hover {
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
}

.chat-card h3 {
    margin-top: 0;
    color: #333;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; 
}

.chat-card p {
    margin: 10px 0;
    color: #666;
    font-size: 14px;
    line-height: 1.4;
}

/* Limit the display of the chat description to only show the first line */
.chat-card p {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.send-chat-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%; /* Ensure the section fills its parent or set a max-width as needed */
    max-width: 600px; /* Example fixed max-width for better control */
}

.chat-textarea {
    width: 100%; /* Adjust width as needed */
    height: 150px; /* Larger textarea */
    border-radius: 8px; /* Rounded corners */
    border: 1px solid #ccc; /* Light grey border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow */
    padding: 10px; /* Padding inside the textarea */
    font-size: 16px; /* Larger text */
    margin-bottom: 20px; /* Space between textarea and button */
    resize: none; /* Disable resizing */
}

.send-chat-button {
    padding: 10px 20px;
    background-color: #1f7fdf; /* Example blue background */
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow for button */
    transition: background-color 0.2s ease-in-out;
}

.send-chat-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}


.tab-switches {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    max-height: 10%;
    padding-bottom: 30px;
  }
  
  .tab-switches button {
    padding: 10px 20px;
    border: 2px solid #1f7fdf; /* Example border color */
    background-color: #FFF;
    color: #007BFF;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-switches button:hover {
    background-color: #1f7fdf;
    color: #FFF;
  }
  
  .tab-switches button.active {
    background-color: #1f7fdf;
    color: #FFF;
  }
  
  /*Modal Conent*/

  .chat-modal-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.chat-modal-content h2 {
    color: #333;
    margin-bottom: 10px;
}

.chat-modal-content h3, .chat-modal-content p {
    color: #666;
    margin: 5px 0;
}

.chat-modal-content button {
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    transition: 0.3s ease;
}

.chat-modal-content .remove-btn {
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
}

.chat-modal-content .close-btn {
    background-color: #6c757d; /* Bootstrap secondary color */
    color: white;
}

/* Adjust button hover effects */
.chat-modal-content button:hover {
    opacity: 0.9;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .chat-cards-container {
    display: grid;
    gap: 20px; /* Space between cards */
    grid-template-columns: repeat(3, 1fr); /* Adjusted to 3 columns */
  }
  
  /* Adjust for smaller screens or default setup */
  @media (max-height: 799px) {
    .chat-cards-container {
      grid-template-rows: repeat(2, 150px); /* Shows 6 cards in total, given 3 per row */
    }
  }
  
  /* For screens with a height of 800px or more */
  @media (min-height: 800px) {
    .chat-cards-container {
      grid-template-rows: repeat(4, 150px); /* 12 cards in total */
    }
  }
  
  /* For screens with a height of 1000px or more */
  @media (min-height: 1000px) {
    .chat-cards-container {
      grid-template-rows: repeat(6, 150px); /* 18 cards in total */
    }
  }
  
  /* For screens with a height of 1200px or more */
  @media (min-height: 1200px) {
    .chat-cards-container {
      grid-template-rows: repeat(6, 150px); /* 24 cards in total */
    }
  }
  