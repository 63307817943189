
.clubs-page-container {
    padding: 20px;
    max-width: 100vw; /* Maximum width of the container */
    /* height: 100%; */
    /* width: 100%; */
    max-height: 100vh;
    margin: auto; /* Centering the container */
    display: flex; /* Use flexbox layout */
    flex-direction: column;
    justify-content: space-between;
  }

  .clubs-column {
    flex: 1; /* Each column takes equal width */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between header and grid */
  }

  .clubs-column-requests{
    flex: 1; /* Each column takes equal width */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between header and grid */
  }

/* Grid Layout for Clubs */
/* .clubs-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(8, 1fr); 
    gap: 20px; 
} */

/* .pagination {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
} */

.pagination {
  display: inline-block;
}

.pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none; /* Remove button border */
  background-color: transparent; /* Transparent background */
  cursor: pointer; /* Cursor to pointer to indicate clickable */
  border-radius: 10px;
}

.pagination button:hover {
  background-color: #ddd; /* Light grey background on hover */
}

.pagination button.active {
  background-color: rgb(230, 230, 230); /* Green background for active button */
  color: white;
}

/* Add styles to clear float if needed */
.pagination:after {
  content: "";
  display: table;
  clear: both;
}


.club-card {
    background-color: white;
    border-radius: 15px; /* Rounded corners like in SwiftUI */
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Padding inside the card */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
    overflow: hidden; /* Ensures content fits within the border radius */
    width: 400px;
    height: 90px;
    display: flex; /* Use flexbox to align content vertically */
    flex-direction: row; /* Stack content vertically */
    justify-content: left; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: left; /* Center text within the card */
}

.club-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.club-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px; /* Rounded corners for image */
margin-right: 15px;
}

.club-info {
  display: inline-block; /* or block, depending on your layout needs */
  width: 100%; /* Ensure it respects the parent container's width */
  font-size: 20px;
  font-weight: bold;
  color: #1d273d;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.club-section-header {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2a3f54;
    margin-bottom: 20px;
    text-align: center;
  }
  

.club-name {
 font-size: clamp(0.8rem, 1vw, 20px);
font-weight: bold;
color: black;

}

.club-description {
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust this value to limit the number of lines */
  -webkit-box-orient: vertical;
}

.member-count {
font-size: 12px;
color: gray;
}



.tab-switches {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  max-height: 10%;
  padding-bottom: 30px;
}

.tab-switches button {
  padding: 10px 20px;
  border: 2px solid #1f7fdf; /* Example border color */
  background-color: #FFF;
  color: #007BFF;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.tab-switches button:hover {
  background-color: #1f7fdf;
  color: #FFF;
}

.tab-switches button.active {
  background-color: #1f7fdf;
  color: #FFF;
}


/*Modal Conent*/

.club-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  padding: 20px;
  color: #333; /* Text color */
}

.club-modal-content h2 {
  margin-bottom: 15px;
}

.club-modal-content p {
  line-height: 1.5;
  margin-bottom: 10px;
}

.club-modal-content a {
  color: #007bff;
}

.club-modal-content button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

/* Button specific styles */
.club-modal-content .close-btn {
  background-color: #6c757d;
  color: white;
}

.club-modal-content .remove-btn {
  background-color: #dc3545;
  color: white;
}

.club-modal-content .approve-btn {
  background-color: #28a745;
  color: white;
}

.club-modal-content .deny-btn {
  background-color: #dc3545;
  color: black;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clubs-grid {
  display: grid;
  gap: 20px; /* Space between cards */
  grid-template-columns: repeat(2, 1fr); /* Always two columns */
  /* No explicit grid-template-rows needed here unless setting a default */
}

/* Adjust for smaller screens or default setup */
@media (max-height: 799px) {
  .clubs-grid {
    grid-template-rows: repeat(2, 90px); /* Shows 4 cards in total, given 2 per row */
  }
}

/* For screens with a height of 800px or more */
@media (min-height: 800px) {
  .clubs-grid {
    grid-template-rows: repeat(4, 90px); /* 8 cards in total */
  }
}

/* For screens with a height of 1000px or more */
@media (min-height: 1000px) {
  .clubs-grid {
    grid-template-rows: repeat(6, 90px); /* 12 cards in total */
  }
}

/* For screens with a height of 1200px or more */
@media (min-height: 1200px) {
  .clubs-grid {
    grid-template-rows: repeat(8, 90px); /* 16 cards in total */
  }
}
