.study-group-page{
    font-family: 'Manrope', sans-serif; 
    padding: 20px;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    flex: 1; /* Each column takes equal width */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between header and grid */
}


.study-group-item{
    background-color: white;
    border-radius: 15px; /* Rounded corners like in SwiftUI */
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Padding inside the card */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
    overflow: hidden; /* Ensures content fits within the border radius */
    width: 400px;
    height: 90px;
    display: flex; /* Use flexbox to align content vertically */
    flex-direction: column; /* Stack content vertically */
    justify-content: left; /* Center content vertically */
    align-items: left; /* Center content horizontally */
    text-align: left; /* Center text within the card */

}
.study-group-list {
    display: grid;
    gap: 20px; /* Space between cards */
  }
  
  /* Default to smaller screens or default setup */
  @media (max-height: 799px) {
    .study-group-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 90px); /* Shows 4 cards in total, given 2 per row */
    }
  }
  
  /* For screens with a height of 800px or more */
  @media (min-height: 800px) {
    .study-group-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 90px); /* 8 cards in total */
    }
  }
  
  /* For screens with a height of 1000px or more */
  @media (min-height: 1000px) {
    .study-group-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 90px); /* 12 cards in total */
    }
  }
  
  /* For screens with a height of 1200px or more */
  @media (min-height: 1200px) {
    .study-group-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr); /* Keeping it consistent with the provided example */
    }
  }

  .delete-study-btn{
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer; background-color: #dc3545;
    color: #fff;
  }

  .modal-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.study-group-modal-content {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  color: #333;
}

  