.resource-page {
  /* font-family: 'League Spartan', sans-serif; */
   font-family: 'Manrope', sans-serif; 
  padding: 20px;
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjusts spacing around items */
  flex-wrap: wrap;
}

.resource-section h2{
  font-size: 1.8rem;
  font-weight: 600;
  color: #2a3f54;
  margin-bottom: 20px;
  text-align: center;
}


.resource-section{
  flex: 1; /* Each column takes equal width */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between header and grid */
}


.resource-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 100%; /* Optional: if you want the inputs to take the full width of the form */
    box-sizing: border-box; /* Optional: ensures padding does not affect the final width */
  }
  

  .resource-item {
    background-color: white;
    border-radius: 15px; /* Rounded corners like in SwiftUI */
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Padding inside the card */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
    overflow: hidden; /* Ensures content fits within the border radius */
    width: 400px;
    height: 100px;
    display: flex; /* Use flexbox to align content vertically */
    flex-direction: row; /* Stack content vertically */
    justify-content: left; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: left; /* Center text within the card */
  }
  
  .resource-item h3{
    display: inline-block; /* or block, depending on your layout needs */
    width: 100%; /* Ensure it respects the parent container's width */
    font-size: 20px;
    font-weight: bold;
    color: #1d273d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tab-switches {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    max-height: 10%;
  }
  
  .tab-switches button {
    padding: 10px 20px;
    border: 2px solid #1f7fdf; /* Example border color */
    background-color: #FFF;
    color: #007BFF;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-switches button:hover {
    background-color: #1f7fdf;
    color: #FFF;
  }
  
  .tab-switches button.active {
    background-color: #1f7fdf;
    color: #FFF;
  }

  .resource-modal-content {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    color: #333;
}

.resource-modal-content a {
    color: #007bff;
    text-decoration: none;
}

.resource-modal-content .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.close-btn, .delete-resource-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-btn {
    background-color: #6c757d;
    color: #fff;
}

.delete-resource-btn {
    background-color: #dc3545;
    color: #fff;
}
/* 
.resource-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(10, 1fr); 
  gap: 20px; 
} */


.resource-list {
  display: grid;
  gap: 20px; /* Space between cards */
}

/* Default to smaller screens or default setup */
@media (max-height: 799px) {
  .resource-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 90px); /* Shows 4 cards in total, given 2 per row */
  }
}

/* For screens with a height of 800px or more */
@media (min-height: 800px) {
  .resource-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 90px); /* 8 cards in total */
  }
}

/* For screens with a height of 1000px or more */
@media (min-height: 1000px) {
  .resource-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 90px); /* 12 cards in total */
  }
}

/* For screens with a height of 1200px or more */
@media (min-height: 1200px) {
  .resource-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr); /* Adjusted to match your original request for 10 rows, but you mentioned 8 in the events-list so keeping it consistent */
  }
}
