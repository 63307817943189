/* Layout.css */

.admin-dashboard {
    display: flex;
    min-height: 100vh; /* Full viewport height */
  }
  
  .content-area {
    flex-grow: 1; /* Take up the remaining space */
    background-color: white; /* White background for the content area */
    padding: 2rem; /* Space inside the content area */
    margin-left: 250px; /* Offset for the sidebar */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .content-area {
      margin-left: 0; /* Remove margin for smaller screens */
      padding: 1rem; /* Adjust padding for smaller screens */
    }
  }
  