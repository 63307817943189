/* AdminPage.css */

.admin-dashboard {
    display: flex;
    min-height: 100vh; /* Full viewport height */
    flex-direction: row;
  }
  
  .sidebar {
    background-color: #1f7fdf; /* Dark blue background */
    color: white; /* White text color */
    width: 250px; /* Sidebar width */
    height: auto;
    padding: 1rem; /* Padding around the content */
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Distribute space around items */
  }
  
  .hamburger-menu {
    display: none; /* Initially hidden, shown in responsive design */
    cursor: pointer;
    padding: 1rem 0;
  }
  
  .admin-title {
    font-size: 1.5rem; /* Larger font for the title */
    margin-bottom: 2rem; /* Space below the title */
    font-weight: 550;
    text-align: left;
  }
  
  .admin-menu {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    margin: 0;
  }
  
  .admin-menu-item {
    display: block;
    text-decoration: none; /* Remove underline from links */
    color: white; /* White text color for menu items */
    padding: 1rem; /* Padding for clickable area */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    text-align: left;
    font-weight: 500;
  }

  .sidebar-icon {
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    margin-right: 8px; /* Adjust the margin as needed */
    filter: invert(100%) brightness(200%);
}

  
  .admin-menu-item:hover,
  .admin-menu-item:focus {
    background-color: #3e8ddb; /* Slightly lighter blue on hover/focus */
  }
  
  .logout-button {
    background-color: #d9534f; /* Bootstrap's btn-danger color */
    color: white;
    padding: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-top: auto;
    border-radius: 20px;
    font-weight: bold;
  }
  
  .logout-button:hover,
  .logout-button:focus {
    background-color: #c9302c; /* Darken the color on hover/focus */
  }
  
  .content-area {
    background-color: white; /* White background for the content area */
    flex-grow: 1; /* Take up the remaining space */
    padding: 2rem; /* Space inside the content area */
    max-height: 100vh;
    max-width: 100%;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .hamburger-menu {
      display: block; /* Show the hamburger menu on smaller screens */
    }
  
    .sidebar {
      position: fixed; /* Fixed position to the side */
      top: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%); /* Hide off-screen to the left */
      transition: transform 0.3s ease; /* Animate the sidebar movement */
    }
  
    .sidebar.open {
      transform: translateX(0); /* When open, move into view */
    }
  
    .content-area {
      padding-left: 1rem; /* Adjust padding for smaller screens */
      padding-right: 1rem;
    }
  }
  