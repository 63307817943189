.payment-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    background-color: #f5f5f5; /* Light gray background */
    
}

.payment-page-card {
    width: 70%; /* Adjust width as needed */
    max-width: 700px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    overflow: hidden;
    position: relative; /* Needed for absolute positioning inside */
}

.payment-page-left-panel {
    background-color: #eee; /* Slightly darker gray for contrast */
    padding: 20px;
    width: 30%; /* Adjust width as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
}

.payment-page-right-panel {
    padding: 20px;
    width: 70%; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-page-input-field,
.payment-page-select {
    width: 90%; /* Adjust width as needed */
    padding: 8px;
    margin: 6px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.payment-page-button {
    background-color: #1f7fdf; /* Green background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    width: 90%;
}

.payment-page-powered-by {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: #666;
}
