.bulletin-page {
  padding: 20px;
  margin: auto;
  background-color: #fff; 
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* Adjusts spacing around items */
  /* height: 100%; */
}

/* Section Headings */
.add-event-section h2, .active-events-section h2, .event-requests-section h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2a3f54;
  margin-bottom: 20px;
  text-align: center;
}

.add-event-section, .event-requests-section, .active-events-section {
  flex: 1; 
  margin: 10px; 
  min-width: 300px; 
  max-height: 100%;
}

.add-event-section {
  display: flex;
  flex: 1;
  min-width: 300px; /* Minimum width for the add event section */
  flex-direction: column;
  align-items: center;
}

.event-requests-section, .active-events-section {
  flex: 1; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
}


/* Event Grid Layout */

/* .events-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(8, 1fr); 
  gap: 20px; 
} */


/* Event Item Styling */
.event-item {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  width: 400px;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: start; /* Aligns items to the start of the container */
  align-items: center; /* Vertically centers items in the container */
}

.event-item:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Event Image */
.event-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10px; /* Rounded corners for image */
  margin-right: 15px;
}

.event-action-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: 10px; /* Space above the buttons */
}

/* Event Details */
.event-details {
  display: flex;
  flex-direction: row; /* Display image and info horizontally */
  justify-content: space-between; /* Space between image and info */
  align-items: center; /* Center vertically within the card */
  
}

.event-name {
  display: inline-block; /* or block, depending on your layout needs */
  width: 100%; /* Ensure it respects the parent container's width */
  font-size: 20px;
  font-weight: bold;
  color: #1d273d;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-info {
  display: flex;
  flex-direction: column; /* Display name and date-time vertically */
  text-align: left; /* Align text to the left */
  max-width: 300px;
}



/* Buttons */
.delete-button, .approve-button {
 padding: 8px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  transition: background-color 0.2s ease;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
}

.approve-button {
  background-color: #2ecc71;
  color: white;
  margin-right: 10px;
}

.delete-button:hover {
  background-color: #c0392b;
}

.approve-button:hover {
  background-color: #27ae60;
}

/* Add Event Form */
.event_form {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.event_inner {
  max-width: 1370px;
  margin: 0 auto;
  background-color: white;
  padding: 75px 0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.event_form .dotes_blue {
  position: absolute;
  top: -65%;
  left: 50%;
  transform: translateX(-50%);
}

.event_form .section_title {
  margin-bottom: 50px;
}

.event_form form .form-group {
  margin-bottom: 30px;
}

.event_form form .form-group .form-control {
  border-radius: 10px;
  color: black;
  height: 60px;
  padding: 10px 20px;
  background-color: rgb(255, 255, 255);
}

.event_form form .form-group .form-control::placeholder {
  color: black;
}

.event_form form .form-group textarea.form-control {
  min-height: 135px;
}

.event_form form .form-group select {
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 10px;
}

.event_form form .purple_btn {
  padding: 9px 50px;
}

.event_form form .form-group .form-control:focus {
  box-shadow: none;
  border-color: var(--dark-gray);
}



.tab-switches {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  max-height: 10%;
  padding-bottom: 30px;
}

.tab-switches button {
  padding: 10px 20px;
  border: 2px solid #1f7fdf; /* Example border color */
  background-color: #FFF;
  color: #007BFF;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.tab-switches button:hover {
  background-color: #1f7fdf;
  color: #FFF;
}

.tab-switches button.active {
  background-color: #1f7fdf;
  color: #FFF;
}

.event-modal-content div {
  margin-bottom: 10px;
}

.event-modal-content {
  display: flex;
  flex-direction: column; /* Or 'row', depending on your layout */
  justify-content: space-around; /* Adjust alignment */
  height: 100%; /* Take up full parent height */
  padding: 20px;
}


.event-modal-content h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;
}

.event-modal-content p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.event-modal-content a {
  color: #007bff;
  text-decoration: none;
}

.event-modal-content button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.event-modal-content button:hover {
  opacity: 0.8;
}

/* Specific styles for buttons */
.event-modal-content .close-btn {
  background-color: #6c757d; /* Bootstrap secondary */
  color: white;
}

.event-modal-content .remove-btn {
  background-color: #dc3545; /* Bootstrap danger */
  color: white;
}

.event-modal-content .approve-btn {
  background-color: #28a745; /* Bootstrap success */
  color: white;
}

.event-modal-content .deny-btn {
  background-color: #ffc107; /* Bootstrap warning */
  color: black;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}


.events-list {
  display: grid;
  gap: 20px; /* Space between cards */
}

/* Default to smaller screens or default setup */
@media (max-height: 799px) {
  .events-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 90px); /* Adjusted for practicality */
  }
}

/* For screens with a height of 800px or more */
@media (min-height: 800px) {
  .events-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 90px); /* 8 cards in total */
  }
}

/* For screens with a height of 1000px or more */
@media (min-height: 1000px) {
  .events-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 90px); /* 12 cards in total */
  }
}

/* For screens with a height of 1200px or more */
@media (min-height: 1200px) {
  .events-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr); /* 8 cards per row */
  }
}