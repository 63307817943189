
.login-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 300px;
    margin: auto; 
}

form {
    display: flex;
    flex-direction: column;
}

input {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}


button { 
    background-color: #1f7fdf; 
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.login-button { 
    background-color: #1f7fdf; 
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}


button:hover {
    background-color: #3988d6; 
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.modal-content {
    position: fixed; 
    top: 50%;
    left: 50%; 
    width: 30%; 
    height: 30%; 
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background-color: #fff;
    overflow: auto;
    outline: none;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


input[type="email"], input[type="password"] {
    padding: 10px;
    margin-bottom: 10px; 
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

form {
    width: 100%; 
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.forgot-password-button {
    background: none;
    border: none;
    color: black; /* Adjust the color as needed */
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
}

.forgot-password-button:hover {
    text-decoration: underline;
}